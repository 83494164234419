import { Box, Card, CardContent, Container, useTheme } from "@mui/material";
import Image from "next/image";
import type { FC } from "react";

type ContainerSize = "lg" | "md" | "sm" | "xl" | "xs";

export const AuthFormWrapper: FC<{
  title: string;
  containerSize?: ContainerSize;
}> = ({ title, containerSize = "sm", children }) => {
  const theme = useTheme();
  return (
    <>
      <title>{title} | Privacy Horizon</title>

      <Box
        sx={{
          backgroundColor: "primary.paper",
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Container maxWidth={containerSize} sx={{ py: 10 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Image
              alt="PHI Logo"
              src={
                theme.palette.mode === "dark"
                  ? "/logos/phi-logo.svg"
                  : "/logos/phi-logo-dark.svg"
              }
              width={220}
              height={220}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mb: 8,
            }}
          />
          <Card>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 4,
              }}
            >
              {children}
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};
