import { Box, Button, Typography, Link as MuiLink } from "@mui/material";
import Link from "next/link";
import { useTranslation } from "react-i18next";

import { AuthFormWrapper } from "components/authentication/AuthFormWrapper";

import { ROUTES } from "constants/routes";

const Custom404 = () => {
  const { t } = useTranslation();

  return (
    <AuthFormWrapper title={t("error.404.title")}>
      <Typography align="center" color="textPrimary" variant="h4">
        This page does not exist
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: 6,
        }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Link href={ROUTES.DASHBOARD} passHref>
          <Button color="primary" variant="contained" component={MuiLink}>
            Back to Home
          </Button>
        </Link>
      </Box>
    </AuthFormWrapper>
  );
};

export default Custom404;
